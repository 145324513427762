import classnames from 'classnames';
import type { PropsWithChildren } from 'react';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './LinkList.css';

type LinkListProps = {
  className?: string;
};

export function LinkList({
  className,
  children,
  ...props
}: PropsWithChildren<LinkListProps & BoxProps>) {
  return (
    <Box
      className={classnames('link-list', Styles.block, className)}
      gap="Normal"
      {...props}
    >
      {children}
    </Box>
  );
}
