import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CheckIcon,
  DotsHorizontalIcon,
  RocketIcon,
} from '@radix-ui/react-icons';

import { Box } from '@zenobius/ui-web-components-box';
import { TextInput } from '@zenobius/ui-web-components-forms-textinput';
import { TextAreaInput } from '@zenobius/ui-web-components-forms-textareainput';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@zenobius/ui-web-components-form';
import { Button } from '@zenobius/ui-web-components-button';

import * as Styles from './ContactForm.css';

const ContactFormSchema = z.object({
  email: z.string().email(),
  message: z.string().min(10),
});

export function ContactForm({
  onSubmit,
  status = 'idle',
}: {
  onSubmit: (data: z.infer<typeof ContactFormSchema>) => void;
  status: 'idle' | 'sending' | 'sent';
}) {
  const form = useForm<z.infer<typeof ContactFormSchema>>({
    resolver: zodResolver(ContactFormSchema),
    defaultValues: {
      email: '',
      message: '',
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <Form {...form}>
      <Box
        direction="column"
        gap="Normal"
        flexGrow={1}
        asChild
      >
        <form onSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <TextInput
                    {...field}
                    {...fieldState}
                    autoComplete="email"
                    disabled={status === 'sending' || status === 'sent'}
                  />
                </FormControl>
                <FormMessage {...fieldState} />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <TextAreaInput
                    {...field}
                    {...fieldState}
                    className={Styles.messageField}
                    disabled={status === 'sending' || status === 'sent'}
                  />
                </FormControl>
                <FormMessage {...fieldState} />
              </FormItem>
            )}
          />
          <Box
            direction="row"
            justifyContent="flex-end"
            gap="Normal"
          >
            {status === 'sending' && (
              <Box
                padding="Normal"
                color="inputLabel"
                alignItems="center"
                gap="Small"
              >
                Sending <DotsHorizontalIcon />
              </Box>
            )}
            {status === 'sent' && (
              <Box
                padding="Normal"
                color="inputLabel"
                alignItems="center"
                gap="Small"
              >
                Sent <CheckIcon />
              </Box>
            )}

            {status === 'idle' && (
              <Button
                type="submit"
                afterElement={<RocketIcon />}
              >
                Send
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Form>
  );
}
