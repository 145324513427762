import { createContext, useContext } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './Site.css';

type IdentifiedPath = {
  pathId: string;
};

const SiteContext = createContext({ pathId: '' });

const Site = ({
  children,
  className,
  pathId,
  ...props
}: BoxProps & IdentifiedPath) => {
  return (
    <SiteContext.Provider value={{ pathId }}>
      <Box
        flexDirection="column"
        className={classnames('site', Styles.block, className)}
        {...props}
      >
        {children}
      </Box>
    </SiteContext.Provider>
  );
};

const Main = function ({ children, className, ...props }: BoxProps) {
  return (
    <Box
      direction="column"
      className={classnames('site__main', Styles.main, className)}
      {...props}
    >
      {children}
    </Box>
  );
};
const Header = function ({ children, className, ...props }: BoxProps) {
  return (
    <Box
      direction="column"
      {...props}
      asChild
      className={classnames('site__header', Styles.header, className)}
    >
      <header>{children}</header>
    </Box>
  );
};

const Footer = function ({ children, className, ...props }: BoxProps) {
  return (
    <Box
      direction="column"
      className={classnames('site__footer', Styles.footer, className)}
      {...props}
      asChild
    >
      {children}
    </Box>
  );
};

Site.Main = Main;
Site.Header = Header;
Site.Footer = Footer;

export { Site, Main, Footer, Header };
