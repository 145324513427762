import type { ComponentProps, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { LinkList } from '@zenobius/ui-web-components-linklist';
import { GroupObjectBy } from '@zenobius/ui-web-components-groupobjectby';

import * as Styles from './TimelineList.css';
import { TimelineItem } from './TimelineItem';
import { TimelineSummaryItem } from './TimelineSummaryItem';
import { TimelinePostLinkItem } from './TimelinePostLinkItem';

const TimelineItemContext = createContext<unknown>(null);

export function TimelineList<T>({
  collection,
  getGroupKey,
  getItemKey,
  sorter,
  children,
}: Pick<
  ComponentProps<typeof GroupObjectBy<T>>,
  'collection' | 'getGroupKey' | 'sorter'
> & {
  children: ReactNode;
  getItemKey: (item: T) => string;
}) {
  return (
    <LinkList
      direction="column"
      className={Styles.listOfYears}
    >
      <GroupObjectBy
        collection={collection}
        getGroupKey={getGroupKey}
        sorter={sorter}
      >
        {(year, items) => (
          <Box
            key={year}
            direction="column"
            className={Styles.yearList}
          >
            <Box
              asChild
              className={Styles.yearHeader}
            >
              <h2>{year}</h2>
            </Box>
            <Box
              asChild
              direction="column"
              className={Styles.postsInYearList}
            >
              <ul>
                {items.map((item) => (
                  <TimelineItemContext.Provider value={item} key={getItemKey(item)}>
                    {children}
                  </TimelineItemContext.Provider>
                ))}
              </ul>
            </Box>
          </Box>
        )}
      </GroupObjectBy>
    </LinkList>
  );
}

export function useTimelineItem<T>(): T {
  const context = useContext(TimelineItemContext);
  if (context === null) {
    throw new Error('useTimelineItem must be used within a TimelineList');
  }
  return context as T;
}

TimelineList.Item = TimelineItem;
TimelineList.SummaryItem = TimelineSummaryItem;
TimelineList.PostLinkItem = TimelinePostLinkItem;
