import classnames from 'classnames';
import { type DetailedHTMLProps } from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { FormInputRecipe } from '@zenobius/ui-web-components-form';

export function TextAreaInput({
  isDirty,
  isTouched,
  isValidating,
  invalid,
  ref,
  ...props
}: {
  isDirty?: boolean;
  isTouched?: boolean;
  isValidating?: boolean;
  invalid?: boolean;
} & DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) {
  return (
    <Box
      asChild
      flexGrow={1}
      className={classnames(
        'text-area-input',
        FormInputRecipe({
          dirty: !!isDirty,
          empty: !props.value,
          error: !!invalid,
          validating: !!isValidating,
        }),
      )}
    >
      <textarea
        {...props}
        placeholder={props.placeholder || ' '}
      />
    </Box>
  );
}
