import classNames from 'classnames';
import sugar from 'sugar';
import type { PropsWithChildren } from 'react';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './TimelineItem.css';

export function TimelineItem({
  type,
  slug,
  title,
  children,
  date,
  stage,
}: PropsWithChildren<{
  type: string;
  date: Date;
  stage: string;
  slug: string;
  title: string;
}>) {
  return (
    <li className={classNames(Styles.post, stage === 'draft' && 'is--draft')}>
      {date && (
        <span className={Styles.date}>
          {sugar.Date.format(sugar.Date.create(date), '%h %d')}
        </span>
      )}
      <Box
        gap="Normal"
        className={Styles.content}
      >
        {stage === 'draft' && (
          <Box
            asChild
            display="inline"
          >
            <span className={Styles.label}>Draft</span>
          </Box>
        )}
        <Box className={Styles.display}>{children}</Box>
      </Box>
    </li>
  );
}
