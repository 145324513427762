import classNames from 'classnames';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './Block.css';

function Block({ children, className, ...props }: BoxProps) {
  return (
    <Box
      display="flex"
      direction="row"
      align="flex-start"
      gap="Normal"
      className={classNames('block', className, Styles.block)}
      {...props}
    >
      {children}
    </Box>
  );
}

function BlockLeft({ children, className, ...props }: BoxProps) {
  return (
    <Box
      className={classNames(
        'block',
        className,
        // Styles.left,
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

function BlockMain({ children, className, ...props }: BoxProps) {
  return (
    <Box
      className={classNames('block', className, Styles.main)}
      {...props}
    >
      {children}
    </Box>
  );
}

function BlockRight({ children, className, ...props }: BoxProps) {
  return (
    <Box
      className={classNames(
        'block',
        className,
        // Styles.right,
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

function BlockHeader({ children, className, ...props }: BoxProps) {
  return (
    <Box
      justify={{ mobileSmall: 'flex-start', laptop: 'flex-end' }}
      className={className}
      {...props}
    >
      {children}
    </Box>
  );
}

Block.Header = BlockHeader;
Block.Left = BlockLeft;
Block.Right = BlockRight;
Block.Main = BlockMain;

export { Block, BlockLeft, BlockRight, BlockMain, BlockHeader };
