import classnames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { FormInputRecipe } from '@zenobius/ui-web-components-form';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  invalid?: boolean;
  isDirty?: boolean;
  isValidating?: boolean;
  isTouched?: boolean;
};

const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, invalid, isDirty, isTouched, isValidating, ...props },
    ref,
  ) => {
    return (
      <Box
        asChild
        className={classnames(
          'text-input',
          FormInputRecipe({
            dirty: !!isDirty,
            empty: !props.value,
            error: !!invalid,
            validating: !!isValidating,
          }),
        )}
      >
        <input
          {...props}
          ref={ref}
          placeholder={props.placeholder || ' '}
          type={type}
        />
      </Box>
    );
  },
);
TextInput.displayName = 'TextInput';

export { TextInput };
