import type { PostDocument } from '@zenobius/content/client';
import { Link } from '@zenobius/ui-web-components-link';

import { useTimelineItem } from './TimelineList';
import { TimelineItem } from './TimelineItem';

export function TimelinePostLinkItem({
  createHref,
}: {
  createHref: (post: PostDocument) => string;
}) {
  const post = useTimelineItem<PostDocument>();

  if (post.template !== 'article') {
    return null;
  }

  return (
    <TimelineItem
      key={post.metadata.slug}
      type={post.template}
      date={post.date}
      stage={post.stage}
      title={post.title}
      slug={post.metadata.slug}
    >
      <Link href={createHref(post)}>{post.title}</Link>
    </TimelineItem>
  );
}
